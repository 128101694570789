import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import logo275 from '../assets/Hirsch275Logo.svg';
import ExternalLink from '../components/ExternalLink';
import { red } from '../utils/colors';
import Timeline from '../components/Timeline';

export const frontmatter = {
  title: 'Chronik',
  menu: 4,
};

const Red = styled.span`
  color: ${red};
`;

interface ChronicItem {
  label: string;
  content: React.ReactElement | string;
}

const chronicItems: ChronicItem[] = [
  {
    label: 'Vor 260 Mio. Jahren',
    content: (
      <>
        streift{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Procynosuchus">
          Procynosuchus
        </ExternalLink>{' '}
        durch die Korbacher Spalte. Damals gab es weder Arzt noch Apotheke,
        daher vermutlich ausgestorben.
      </>
    ),
  },
  {
    label: 'um 400 v. Chr.',
    content: (
      <>
        <ExternalLink href="https://de.wikipedia.org/wiki/Hippokrates_von_Kos">
          Hippokrates
        </ExternalLink>{' '}
        begründet die antike Medizin.
      </>
    ),
  },
  {
    label: 'ab 1231',
    content: (
      <>
        <ExternalLink href="https://de.wikipedia.org/wiki/Friedrich_II._%28HRR%29">
          Stauferkaiser Friedrich II.
        </ExternalLink>{' '}
        verfügt die ersten gesetzlichen Grundlagen zum Betrieb von Apotheken im
        „Edikt von Salerno“.
      </>
    ),
  },
  {
    label: 'um 1525',
    content: (
      <>
        <ExternalLink href="https://de.wikipedia.org/wiki/Paracelsus">
          Paracelsus
        </ExternalLink>{' '}
        ist als Arzt, Alchemist, Astrologe und Philosoph tätig.
      </>
    ),
  },
  {
    label: '1535',
    content: (
      <>
        <Red>Apotheker Heinrich Knyben</Red> wird erstmals erwähnt. Er ist
        vermutlich am Hof des Grafen Philipp III. auf dem Eisenberg tätig. Seine
        Grabplatte ist heute Bestandteil des Altars der Nikolaikirche.
      </>
    ),
  },
  {
    label: '1582',
    content: (
      <>
        <Red>Apotheker Johann Hetzel</Red> heiratet Maria Wogesser aus Gießen,
        er hat seine Apotheke auf der Stechbahn, ist Mitglied des Stadtrates und
        wird von 1601-1604 regierender Bürgermeister.
      </>
    ),
  },
  {
    label: '1648',
    content: (
      <>
        Das Ende des 30jährigen Krieges hinterlässt ein{' '}
        <Red>verwüstetes Korbach</Red>, nur noch 235 Häuser sind bewohnbar, die
        Einwohnerzahl ist von 2600 auf ca. 1100 gesunken.
      </>
    ),
  },
  {
    label: '1664',
    content: (
      <>
        Ein verheerender <Red>Brand</Red> vernichtet 198 Häuser, betroffen sind
        besonders Stechbahn, Lengefelder Straße, Klosterstraße und Tempel,
        danach setzt eine rege Bautätigkeit ein.
      </>
    ),
  },
  {
    label: 'um 1670',
    content: (
      <>
        Andreas Nikolaus Frankenberger wird als Besitzer der{' '}
        <Red>Engel-Apotheke</Red> auf der Stechbahn gegenüber dem Rathaus
        genannt, geht aber schon 1685 als gräflicher Hofapotheker nach
        Mengeringhausen.
      </>
    ),
  },
  {
    label: '1685',
    content: (
      <>
        Abraham Weimer pachtet die Engel-Apotheke, gründet später eine eigene
        Apotheke etwa an der Stelle der heutigen Waldeccia, die spätere
        <Red>Contzen`sche Apotheke</Red>.
      </>
    ),
  },
  {
    label: '1712',
    content: (
      <>
        Die Grafschaft{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Waldeck#F.C3.BCrstentum_Waldeck_1712_bis_1848">
          Waldeck und Pyrmont
        </ExternalLink>{' '}
        wird zum Fürstentum. Graf Friedrich Anton Ulrich lässt das
        Residenzschloss in Arolsen erbauen, nachdem Korbacher Bürger den Bau in
        der Neustadt verhindert haben.
      </>
    ),
  },
  {
    label: '1734',
    content: (
      <>
        <Red>Johann Moritz Varnhagen</Red>, der Besitzer der Engel-Apotheke,
        stirbt. Sein Sohn Christian Ludwig, der die Apotheke später übernehmen
        soll, ist erst 4 Jahre alt. Daher verpachtet die Witwe Varnhagen 1738 an
        <Red>Johann Adolf Schmidt</Red>, ein Neffe des Verstorbenen.
      </>
    ),
  },
  {
    label: '1740',
    content: (
      <>
        Friedrich II. wird König in Preußen. Als Vertreter des{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Aufgekl%C3%A4rter_Absolutismus">
          aufgeklärten Absolutismus
        </ExternalLink>{' '}
        schafft er noch im selben Jahr die Folter ab.
      </>
    ),
  },
  {
    label: '1741',
    content: (
      <>
        Schmidt gründet nach ständigen Querelen mit der Witwe Varnhagen seine
        eigene Apotheke, die <Red>Hirsch-Apotheke</Red>.
      </>
    ),
  },
  {
    label: '1745',
    content: (
      <>
        Apotheker Schmidt erwirbt ein Wohnhaus in der unteren Landstraße, das
        von zwei alten Jungfern bewohnt wurde und im Volksmund den Namen
        <Red>„Zipperburg“</Red> trug.
      </>
    ),
  },
  {
    label: '1746',
    content: (
      <>
        Schmidt lässt das alte Haus abreißen und die neue Hirsch-Apotheke im
        Wert von 550 Talern errichten.
      </>
    ),
  },
  {
    label: '1752',
    content: (
      <>
        Im Kurfürstentum Bayern stirbt die letzte gerichtlich verurteilte „Hexe“
        auf dem{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Hexenverfolgung">
          Scheiterhaufen
        </ExternalLink>
        .
      </>
    ),
  },
  {
    label: '1756',
    content: (
      <>
        Der{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Siebenj%C3%A4hriger_Krieg">
          7-jährige Krieg
        </ExternalLink>{' '}
        beginnt.
      </>
    ),
  },
  {
    label: '1760',
    content: (
      <>
        Die{' '}
        <ExternalLink href="http://regiowiki.hna.de/Schlacht_bei_Korbach">
          Schlacht bei Korbach
        </ExternalLink>{' '}
        zwischen Preußen und Franzosen führt zu verheerenden Verwüstungen und
        Kriegsschäden. Die Not der Einwohner ist groß.
      </>
    ),
  },
  {
    label: '1789',
    content: (
      <>
        <ExternalLink href="https://de.wikipedia.org/wiki/Franz%C3%B6sische_Revolution">
          Französische Revolution
        </ExternalLink>
      </>
    ),
  },
  {
    label: '1805',
    content: (
      <>
        Die <Red>Konkurrenz</Red> unter den 3 Apotheken wird so groß, dass die
        Apotheker Krüger und Kunkell versuchen, die Contzen´sche Apotheke durch
        fürstliche Verordnung schließen zu lassen.
      </>
    ),
  },
  {
    label: '1836',
    content: (
      <>
        Nach langjährigem <Red>Rechtsstreit</Red> wird die Contzen`sche Apotheke
        von den beiden Konkurrenten aufgekauft und im gleichen Jahr stillgelegt.
      </>
    ),
  },
  {
    label: '1848',
    content: (
      <>
        <Red>Märzrevolution</Red>, in der Folge die erste demokratisch gewählte
        Nationalversammlung
      </>
    ),
  },
  {
    label: '1869',
    content: (
      <>
        Gründung der <Red>Rüdiger-Heilanstalt</Red> für arme Kranke in Korbach.
      </>
    ),
  },
  {
    label: '1875',
    content: (
      <>
        <Red>Carl Kümmell</Red> übernimmt die Hirsch-Apotheke. Sein jüngerer
        Bruder{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Hermann_K%C3%BCmmell">
          Hermann Kümmell
        </ExternalLink>{' '}
        wurde Arzt und später hervorragender Chirurg in Hamburg, er blieb seiner
        Heimat treu verbunden und wurde Ehrenbürger der Stadt Korbach.{' '}
      </>
    ),
  },
  {
    label: '1883',
    content: (
      <>
        Otto von Bismarck führt die erste gesetzliche{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Krankenversicherung_in_Deutschland#Geschichte">
          Krankenversicherung
        </ExternalLink>{' '}
        ein, bis 1889 folgen weitere Bausteine unserer heutigen
        Sozialversicherung.
      </>
    ),
  },
  {
    label: '1889',
    content: (
      <>
        Die Hirsch-Apotheke wird nach dem Tode Kümmells an dessen Provisor (den
        bisherigen Stellvertreter des Inhabers) <Red>Adolf Nacke</Red> verkauft,
        sie wurde bis dahin immer von den Erben des Gründers Johann Adolf
        Schmidt betrieben.
      </>
    ),
  },
  {
    label: '1893',
    content: (
      <>
        Korbach erhält eine <Red>Bahnstation</Red>.
      </>
    ),
  },
  {
    label: '1896',
    content: (
      <>
        Erste{' '}
        <ExternalLink href="https://de.wikipedia.org/wiki/Olympische_Spiele#Wiederbelebung_der_Spiele">
          olympische Spiele
        </ExternalLink>{' '}
        der Neuzeit in Athen
      </>
    ),
  },
  {
    label: '1899',
    content: (
      <>
        <ExternalLink href="https://de.wikipedia.org/wiki/Acetylsalicyls%C3%A4ure">
          Aspirin
        </ExternalLink>
        , eins der ersten industriell hergestellten Arzneimittel wird beim
        Kaiserlichen Patentamt eingetragen.
      </>
    ),
  },
  {
    label: '1911',
    content: (
      <>
        Der Neubau der Rüdiger-Heilanstalt zählt 15 Betten und gilt als{' '}
        <Red>erstes Krankenhaus</Red> Korbachs.
      </>
    ),
  },
  {
    label: '1922-34',
    content: (
      <>
        Nach dem Tod von Adolf Nacke wird die Hirsch-Apotheke von{' '}
        <Red>Paul Quinke</Red>
        verwaltet.
      </>
    ),
  },
  {
    label: '1934-52',
    content: (
      <>
        <Red>Friedrich Scheer</Red> aus Lingen/Ems verwaltet erst die Apotheke
        und wird 1936 Pächter. Er lässt das Fachwerk freilegen und die Gefache
        mit Bruchsteinen ausfüllen
      </>
    ),
  },
  {
    label: '1952',
    content: (
      <>
        <Red>Marita Büllesbach</Red>, eine Enkelin von Adolf Nacke, übernimmt
        mit ihrem Mann Gerd die Apotheke.
      </>
    ),
  },
  {
    label: '1960',
    content: (
      <>
        Das sogenannte Apotheken-Urteil beendet die Zeit des Privilegs und der
        Konzessionen, die nur bei Bedarf erteilt wurden, und ermöglicht die
        <ExternalLink href="https://de.wikipedia.org/wiki/Apotheke#Geschichte">
          Niederlassungsfreiheit
        </ExternalLink>{' '}
        für alle Apotheker.
      </>
    ),
  },
  {
    label: '1961',
    content: (
      <>
        <Red>Hans Hermann</Red> leitet die Apotheke in den Zeiten des
        Wirtschaftswunders, die Zahl der Apotheken in Korbach hat sich bis 1979
        auf sechs erhöht.
      </>
    ),
  },
  {
    label: '1977',
    content: (
      <>
        Erstes Kostendämpfungsgesetz nach dem 2.Weltkrieg, die{' '}
        <Red>Zuzahlung</Red> beträgt nun 1 DM pro Medikament.
      </>
    ),
  },
  {
    label: '1997',
    content: (
      <>
        <Red>Franz Kirchner</Red> übernimmt die Leitung der Hirsch-Apotheke, die
        sich noch immer im Besitz der Nackeschen Erben befindet.
      </>
    ),
  },
  {
    label: '2002',
    content: (
      <>
        Kirchner sorgt mit der neu eingeführten individualisierten
        Gesundheitskarte für eine intensivierte pharmazeutische Betreuung seiner
        Kunden. Sein <Red>Qualitätsmanagement</Red> wird zertifiziert.
      </>
    ),
  },
  {
    label: '2004',
    content: (
      <>
        Das Gebäude der Hirsch-Apotheke wird unter Leitung von Architekt Kepplin
        von Juli bis November <Red>umfassend saniert</Red>.
      </>
    ),
  },
  {
    label: '2014',
    content: (
      <>
        Das Entgelt der Apotheken wird seit 10 Jahren erstmals um 3%(!) erhöht.
        Die Zahl der Apotheken in Deutschland hat sich nach einem Höchststand
        von 21.602 auf 20.441 Ende 2014 reduziert.
      </>
    ),
  },
  {
    label: '2016',
    content: (
      <>
        Die Hirsch-Apotheke feiert ihr <Red>275-jähriges Bestehen</Red> unter
        anderem mit einer Ausstellung im Bonhage-Museum.
      </>
    ),
  },
];

export default () => (
  <Layout>
    <Helmet>
      <title>Chronik</title>
    </Helmet>
    <h1>1741 - 2016: Eine Chronik</h1>
    <p>
      Die Hirsch-Apotheke ist die älteste bestehende Apotheke in Korbach und
      damit eines der traditionsreichsten Unternehmen der Hansestadt.
    </p>
    <p>
      Der Gründer Johann Adolf Schmidt aus Altena/Wf. war bei seinem Onkel
      Johann Moritz Varnhagen in der Engel-Apotheke in der Stechbahn (im Haus
      der späteren Buchhandlung Urspruch) schon in der Lehre und wurde nach
      dessen Tod 1738 dort Pächter. Nach ständigen Streitigkeiten mit der Witwe
      Varnhagens gründete er 1741 aber kurzerhand seine eigene – die
      Hirsch-Apotheke, die bis 1822 auch das Prädikat einer Hochfürstlich
      waldeckischen Hofapotheke führte.
    </p>
    <p>
      Schon früh gab es Verbindungen der Hirsch-Apotheke zum Korbacher
      Gymnasium, denn die Tochter Schmidts, Maria Christiane, heiratete 1761 den
      Prorektor (Stellvertreter des Schulleiters) Johann Gottfried Speirmann.
      Die Hirsch-Apotheke blieb 148 Jahre im Besitz der Familie. Letzter Inhaber
      war Carl Kümmell, dessen jüngerer Bruder Hermann ein bedeutender Chirurg
      und Ehrenbürger Korbachs wurde.
    </p>
    <p>
      1889 erwarb der Apotheker-Geselle Adolf Nacke, bisheriger Provisor (im 19.
      Jhd. übliche Bezeichnung für den Stellvertreter des Inhabers) die
      Hirsch-Apotheke, dessen Erben noch heute die Eigentümer sind. Die
      Hirsch-Apotheke wird damit immer noch auf der Grundlage des damaligen
      Konzessionsrechts betrieben (wie noch zwei weitere Apotheken in Hessen).
    </p>
    <Timeline>
      {chronicItems.map((item) => (
        <li key={item.label}>
          <ChronicTitle>{item.label}</ChronicTitle>
          <p>{item.content}</p>
        </li>
      ))}
      <li>
        <ChronicLogo src={logo275} alt="275 Jahre Hirsch-Apotheke Korbach" />
      </li>
    </Timeline>
    {}
  </Layout>
);

const ChronicTitle = styled.h3`
  margin-bottom: 10px;
`;

const ChronicLogo = styled.img`
  width: 250px;
  margin: 0 auto;
`;
